
global.$ = global.jQuery = require('jquery');
jQuery.event.special.touchstart = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.touchmove = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.wheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("wheel", handle, { passive: true });
    }
};
jQuery.event.special.mousewheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("mousewheel", handle, { passive: true });
    }
};

//require('jquery-ui/ui/effects/effect-slide.js');
//require('jquery-ui/ui/widgets/datepicker.js');
//require('jquery-ui/ui/i18n/datepicker-hr.js');

window.bootstrap = require('bootstrap');

//require('@popperjs/core');
require('owl.carousel');
require('magnific-popup');
require('jquery-form');
window.Swal = require('sweetalert2');
//require('jquery-zoom');
//require('swiper/bundle');
//require('select2');
//require('slick-carousel');
//require('choices.js');

//require('waypoints/lib/jquery.waypoints.min.js');
//require('jcarousel');
//require('bootstrap-star-rating');
require('./_notifications.js');
require('./_forms.js');
//require('./_extreme.js');
require('./_cookies.js');
//require('./_search.js');
//require('./_swiper.js');
//require('./main.js');
/*
try {
    require('./http.js');
    require('./newsletter.js');
    require('./cookies.js');
} catch (e) {
    console.log(e);
}
*/



